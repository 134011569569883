<template>
  <section>
    <div id="iframe-overlay" @click="HideWebView"></div>
    <iframe id="unity-iframe"></iframe>
    <div class="unity-container relative w-full maxWebGLWidth z-50">
      <canvas
        ref="unityCanvas"
        id="unity-canvas"
        class="w-full aspect-video maxWebGLWidth"
      ></canvas>

      <!-- Loading Bar -->
      <div ref="loadingBar" id="unity-loading-bar" class="absolute">
        <div id="unity-progress-bar-full"></div>
      </div>

      <!-- Warning Banner -->
      <div ref="warningBanner" id="unity-warning"></div>
    </div>
  </section>
</template>

<script>
const BASE_URL = "https://nihssplus-assets.apexinnovations.com/NIHSSPlus";
const UNITY_PATHS = {
  BUILD: `${BASE_URL}/Build`,
  STREAMING: `${BASE_URL}/StreamingAssets`,
  get LOADER() {
    return `${this.BUILD}/WebGL_Beta.loader.js`;
  },
  get DATA() {
    return `${this.BUILD}/WebGL_Beta.data.unityweb`;
  },
  get FRAMEWORK() {
    return `${this.BUILD}/WebGL_Beta.framework.js.unityweb`;
  },
  get WASM() {
    return `${this.BUILD}/WebGL_Beta.wasm.unityweb`;
  },
};

export default {
  name: "UnityWebGL",
  data() {
    return {
      globalUnityInstance: null,
      buildUrl: "https://nihssplus-assets.apexinnovations.com/NIHSSPlus/Build",
      config: {
        dataUrl: UNITY_PATHS.DATA,
        frameworkUrl: UNITY_PATHS.FRAMEWORK,
        codeUrl: UNITY_PATHS.WASM,
        streamingAssetsUrl: UNITY_PATHS.STREAMING,
        companyName: "ApexInnovations",
        productName: "NIHSS+",
        productVersion: "1.40.00",
      },
    };
  },
  mounted() {
    this.initUnity();
    window.LoadWebView = this.LoadWebView;
    window.AskForMessage = this.AskForMessage;
    window.HideWebView = this.HideWebView;
  },
  props: {
    auth_token: {
      type: String,
      required: true,
    },
  },
  methods: {
    initUnity() {
      const loaderUrl = this.buildUrl + "/WebGL_Beta.loader.js";
      const canvas = this.$refs.unityCanvas;
      const loadingBar = this.$refs.loadingBar;

      // Mobile Configuration
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        const meta = document.createElement("meta");
        meta.name = "viewport";
        meta.content =
          "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes";
        document.getElementsByTagName("head")[0].appendChild(meta);
        canvas.className = "unity-mobile";
      } else {
        canvas.style.width = "960px";
        canvas.style.height = "600px";
      }

      loadingBar.style.display = "block";

      const script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        createUnityInstance(canvas, this.config, (progress) => {
          const progressBarFull = this.$refs.loadingBar.querySelector(
            "#unity-progress-bar-full"
          );
          progressBarFull.style.width = 100 * progress + "%";
        })
          .then((unityInstance) => {
            this.globalUnityInstance = unityInstance;
            loadingBar.style.display = "none";
          })
          .catch((message) => {
            alert(message);
          });
      };
      this.config.showBanner = this.unityShowBanner;
      document.body.appendChild(script);
    },
    enableFullscreen() {
      this.globalUnityInstance.SetFullscreen(1);
    },
    disableFullscreen() {
      this.globalUnityInstance.SetFullscreen(0);
    },
    LoadWebView(url) {
      const iframe = document.getElementById("unity-iframe");
      const overlay = document.getElementById("iframe-overlay");
      iframe.src = url;
      iframe.style.display = "block";
      overlay.style.display = "block";
    },
    HideWebView() {
      const iframe = document.getElementById("unity-iframe");
      const overlay = document.getElementById("iframe-overlay");
      iframe.src = "";
      iframe.style.display = "none";
      overlay.style.display = "none";
    },
    AskForMessage() {
      if (this.auth_token) {
        this.globalUnityInstance.SendMessage(
          "AutoLogin",
          "JavascriptReceive",
          this.auth_token
        );
      } else {
        console.log("Nani url token not there");
      }
      return "Message received";
    },
    unityShowBanner(msg, type) {
      const warningBanner = this.$refs.warningBanner;
      const div = document.createElement("div");
      div.innerHTML = msg;

      if (type == "error") {
        div.style = "background: red; padding: 10px;";
      } else {
        if (type == "warning") {
          div.style = "background: yellow; padding: 10px;";
        }
        setTimeout(() => {
          warningBanner.removeChild(div);
          this.updateBannerVisibility();
        }, 5000);
      }
      warningBanner.appendChild(div);
      this.updateBannerVisibility();
    },
    updateBannerVisibility() {
      const warningBanner = this.$refs.warningBanner;
      warningBanner.style.display = warningBanner.children.length
        ? "block"
        : "none";
    },
  },
};
</script>

<style scoped>
#unity-iframe {
  display: none;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  height: 50%;
  border: 2px solid black;
  z-index: 1000;
  background-color: white;
}
#iframe-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.nihss-container {
  display: flex;
  justify-content: center;
}
.unity-container {
  position: relative;
}

#unity-canvas {
  background: #1f1f20;
}

.unity-mobile #unity-canvas {
  width: 100%;
  height: 100%;
}

#unity-loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

#unity-progress-bar-full {
  width: 0%;
  height: 100%;
  background: white;
}

#unity-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}
</style>
