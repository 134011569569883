import NIHSSAxios  from  './interceptor.js' 
import axios from 'axios';
import qs from 'qs';
import firebase from 'firebase';

class AdminLMSController {

    AICC_SID = null;
    AICC_URL = null;
    CLIENT_ID = null;
    COURSE_ID = null;
    ORDERSECRET = null;
    ORGSECRET = null; 

    /**
     * @description Check if all the required parameters are set
     * @returns {Boolean} 
     */
    check(){
        if(this.AICC_SID && this.AICC_URL && this.CLIENT_ID && this.COURSE_ID && this.ORDERSECRET && this.ORGSECRET){
            return true;
        }
        return false;
    }

    /**
     * @description Simply
     * @param {*} jwt 
     * @returns 
     */
    async validateToken(jwt){
        return jwt ? true : false
    }

    /**
     * @description Returns a valid login user token
     * @returns {Object}
     */
    async getValidUserToken(){
        const response = await NIHSSAxios.post(`/api/users/auth/lms/session/start`)
        return response?.data || false;
    }

    /**
     * @description this should create a new account for the user and allow them to login
     * @returns {Object}
     */
    async requestSessionAuthentication(){
        if(this.check()){
            const response = await NIHSSAxios.post(`/api/users/auth/lms/session`, {
                AICC_SID: this.AICC_SID,
                CLIENT_ID: this.CLIENT_ID,
                COURSE_ID: this.COURSE_ID,
                ORDER_SECRET: this.ORDERSECRET,
                ORG_SECRET: this.ORGSECRET
            })
            return response || null;
        }
        return null;
    }

    /**
     * @description Validate the response from the requestSessionAuthentication method
     * @param {*} response 
     * @returns 
     */
    validateSessionResponse(response){
        const data = response.data || null;
        //Check for possible errors
        if(response.error === true || !data.lmsSessionToken ){
            throw new Error("Looks like there was an error with the request, please try again later.");
        }
        return data
    }
}

export default AdminLMSController;